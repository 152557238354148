import React from 'react';
import { injectIntl } from 'react-intl';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListIcon from '@material-ui/icons/List';
import DashboardIcon from '@material-ui/icons/Dashboard';
import PlaceIcon from '@material-ui/icons/Place';
import CategoryIcon from '@material-ui/icons/Category';
import BallotIcon from '@material-ui/icons/Ballot';
import GroupWorkIcon from '@material-ui/icons/GroupWork';
import SettingsIcon from '@material-ui/icons/Settings';
import ListItemText from '@material-ui/core/ListItemText';
import { NavLink } from 'react-router-dom';
import Link from '@material-ui/core/Link';

const Menu = (props) => (
  <div>
    <NavLink to="/" component={Link} color="inherit">
      <ListItem button>
        <ListItemIcon>
          <DashboardIcon />
        </ListItemIcon>
        <ListItemText primary={props.intl.formatMessage({ id: 'Dashboard' })} />
      </ListItem>
    </NavLink>
    <br />
    <NavLink to="/place" component={Link} color="inherit">
      <ListItem button>
        <ListItemIcon>
          <PlaceIcon />
        </ListItemIcon>
        <ListItemText primary={props.intl.formatMessage({ id: 'Add place' })} />
      </ListItem>
    </NavLink>
    <NavLink to="/places-list" component={Link} color="inherit">
      <ListItem button>
        <ListItemIcon>
          <ListIcon />
        </ListItemIcon>
        <ListItemText primary={props.intl.formatMessage({ id: 'Places list' })} />
      </ListItem>
    </NavLink>
    <br />
    <NavLink to="/category" component={Link} color="inherit">
      <ListItem button>
        <ListItemIcon>
          <CategoryIcon />
        </ListItemIcon>
        <ListItemText primary={props.intl.formatMessage({ id: 'Add category' })} />
      </ListItem>
    </NavLink>
    <NavLink to="/categories-list" component={Link} color="inherit">
      <ListItem button>
        <ListItemIcon>
          <ListIcon />
        </ListItemIcon>
        <ListItemText primary={props.intl.formatMessage({ id: 'Categories list' })} />
      </ListItem>
    </NavLink>
    <br />
    <NavLink to="/attribute" component={Link} color="inherit">
      <ListItem button>
        <ListItemIcon>
          <BallotIcon />
        </ListItemIcon>
        <ListItemText primary={props.intl.formatMessage({ id: 'Add attribute' })} />
      </ListItem>
    </NavLink>
    <NavLink to="/attributes-group" component={Link} color="inherit">
      <ListItem button>
        <ListItemIcon>
          <GroupWorkIcon />
        </ListItemIcon>
        <ListItemText primary={props.intl.formatMessage({ id: 'Add attributes group' })} />
      </ListItem>
    </NavLink>
    <NavLink to="/attributes-list" component={Link} color="inherit">
      <ListItem button>
        <ListItemIcon>
          <ListIcon />
        </ListItemIcon>
        <ListItemText primary={props.intl.formatMessage({ id: 'Attributes list' })} />
      </ListItem>
    </NavLink>
    <NavLink to="/attributes-groups-list" component={Link} color="inherit">
      <ListItem button>
        <ListItemIcon>
          <ListIcon />
        </ListItemIcon>
        <ListItemText primary={props.intl.formatMessage({ id: 'Attributes groups list' })} />
      </ListItem>
    </NavLink>
    <br />
    <NavLink to="/settings" component={Link} color="inherit">
      <ListItem button>
        <ListItemIcon>
          <SettingsIcon />
        </ListItemIcon>
        <ListItemText primary={props.intl.formatMessage({ id: 'Settings' })} />
      </ListItem>
    </NavLink>
  </div>
);

export default injectIntl(Menu);
