import React from 'react';
import ButterToast, { Cinnamon } from 'butter-toast';
import CheckIcon from '@material-ui/icons/Check';
import FailIcon from '@material-ui/icons/CancelOutlined';
import InfoIcon from '@material-ui/icons/Info';

export const SUCCESS = 'SUCCESS';
export const ERROR = 'ERROR';
export const INFO = 'INFO';

const iconResolver = (type) => {
  switch (type) {
    case SUCCESS:
      return (<CheckIcon />);
    case ERROR:
      return <FailIcon />;
    default:
      return <InfoIcon />;
  }
};

const schemeResolver = (type) => {
  switch (type) {
    case SUCCESS:
      return Cinnamon.Crunch.SCHEME_GREEN;
    case ERROR:
      return Cinnamon.Crunch.SCHEME_RED;
    default:
      return Cinnamon.Crunch.SCHEME_BLUE;
  }
};

export const notify = (type, title, content) => {
  ButterToast.raise({
    content: <Cinnamon.Crunch
      icon={iconResolver(type)}
      title={title}
      content={content}
      scheme={schemeResolver(type)}
    />,
    timeout: 4000
  });
};

Notification.propTypes = {};
