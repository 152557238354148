import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { injectIntl } from 'react-intl';
import AttributeForm from '../Attributes/AttributeForm';
import withRestrictedAccess from '../../higherOrderComponents/withRestrictedAccess';
import withLayout from '../../higherOrderComponents/withLayout';
import { attributeService } from '../../services/api/attributeService';
import { notify, SUCCESS, ERROR } from '../../services/notification';
import logger from '../../services/logger';

const AttributeEdit = (props) => {
  const { id } = props.match.params;
  const isAddMode = !id;
  const history = useHistory();

  const createAttribute = (values, actions) => {
    attributeService
      .create(values)
      .then(() => {
        notify(
          SUCCESS,
          props.intl.formatMessage({ id: 'Yay' }),
          props.intl.formatMessage({ id: 'Attribute has been added.' }),
        );
        history.push('/attributes-list');
      })
      .catch((e) => {
        logger.validationLog(e.response.data);

        notify(
          ERROR,
          props.intl.formatMessage({ id: 'Opss...' }),
          e.response.data?.type === 'validation_error'
            ? props.intl.formatMessage({ id: 'Validation error. Check console logs.' })
            : props.intl.formatMessage({ id: 'Adding new attribute failed.' }),
        );
      })
      .finally(() => {
        actions.setSubmitting(false);
      });
  };

  const updateAttribute = (id, values, actions) => {
    attributeService
      .update(id, values)
      .then(() => {
        notify(
          SUCCESS,
          props.intl.formatMessage({ id: 'Yay' }),
          props.intl.formatMessage({ id: 'Attribute has been updated.' }),
        );
        history.push('/attributes-list');
      })
      .catch((e) => {
        logger.validationLog(e.response.data);

        notify(
          ERROR,
          props.intl.formatMessage({ id: 'Opss...' }),
          e.response.data?.type === 'validation_error'
            ? props.intl.formatMessage({ id: 'Validation error. Check console logs.' })
            : props.intl.formatMessage({ id: 'Updating attribute failed.' }),
        );
      })
      .finally(() => {
        actions.setSubmitting(false);
      });
  };

  return (
    <>
      <h2>{ props.intl.formatMessage({ id: isAddMode ? 'Add attribute' : 'Update attribute' }) }</h2>
      <AttributeForm
        id={id}
        isAddMode={isAddMode}
        create={createAttribute}
        update={updateAttribute}
      />
    </>
  );
};

AttributeEdit.propTypes = {};

const enhance = compose(
  connect((state) => state.authReducer),
  withRestrictedAccess,
  withLayout,
  injectIntl,
);

export default enhance(AttributeEdit);
