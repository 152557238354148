import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { injectIntl } from 'react-intl';
import withRestrictedAccess from '../../higherOrderComponents/withRestrictedAccess';
import withLayout from '../../higherOrderComponents/withLayout';
import CategoryForm from '../Categories/CategoryForm';
import { categoryService } from '../../services/api/categoryService';
import { ERROR, notify, SUCCESS } from '../../services/notification';
import logger from '../../services/logger';

const CategoryEdit = (props) => {
  const { id } = props.match.params;
  const isAddMode = !id;
  const history = useHistory();

  const createCategory = (values, actions) => {
    categoryService
      .create(values)
      .then(() => {
        notify(
          SUCCESS,
          props.intl.formatMessage({ id: 'Yay' }),
          props.intl.formatMessage({ id: 'Category has been added.' }),
        );
        history.push('/categories-list');
      })
      .catch((e) => {
        logger.validationLog(e.response.data);

        notify(
          ERROR,
          props.intl.formatMessage({ id: 'Opss...' }),
          e.response.data?.type === 'validation_error'
            ? props.intl.formatMessage({ id: 'Validation error. Check console logs.' })
            : props.intl.formatMessage({ id: 'Adding new category failed.' }),
        );
      })
      .finally(() => {
        actions.setSubmitting(false);
      });
  };

  const updateCategory = (id, values, actions) => {
    categoryService
      .update(id, values)
      .then(() => {
        notify(
          SUCCESS,
          props.intl.formatMessage({ id: 'Yay' }),
          props.intl.formatMessage({ id: 'Category has been updated.' }),
        );
        history.push('/categories-list');
      })
      .catch((e) => {
        logger.validationLog(e.response.data);

        notify(
          ERROR,
          props.intl.formatMessage({ id: 'Opss...' }),
          e.response.data?.type === 'validation_error'
            ? props.intl.formatMessage({ id: 'Validation error. Check console logs.' })
            : props.intl.formatMessage({ id: 'Updating category failed.' }),
        );
      })
      .finally(() => {
        actions.setSubmitting(false);
      });
  };

  return (
    <>
      <h2>{ props.intl.formatMessage({ id: isAddMode ? 'Add category' : 'Update category' }) }</h2>
      <CategoryForm
        id={id}
        isAddMode={isAddMode}
        create={createCategory}
        update={updateCategory}
      />
    </>
  );
};

const enhance = compose(
  connect((state) => state.authReducer),
  withRestrictedAccess,
  withLayout,
  injectIntl,
);

export default enhance(CategoryEdit);
