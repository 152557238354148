import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl } from 'react-intl';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/AddCircle';
import { makeStyles } from '@material-ui/core/styles';
import withLayout from '../../higherOrderComponents/withLayout';
import withRestrictedAccess from '../../higherOrderComponents/withRestrictedAccess';
import { categoryService } from '../../services/api/categoryService';
import { ERROR, notify, SUCCESS } from '../../services/notification';
import CategoryDataTable from '../Datatable/CategoryDataTable';

const useStyles = makeStyles((theme) => ({
  buttonContainer: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(3),
    marginBottom: theme.spacing(3),
    alignSelf: 'flex-end',
    textDecoration: 'none',
  },
  button: {
    width: '200px',
  }
}));

const CategoriesList = (props) => {
  const [categories, setCategories] = useState([]);
  const [isProcessing, setIsProcessing] = useState(false);
  const [isMounting, setIsMounting] = useState(true);
  const history = useHistory();

  const classes = useStyles();

  useEffect(() => {
    categoryService
      .getAll()
      .then((res) => {
        setCategories(res.data);
        setIsMounting(false);
      })
      .catch(() => {
        notify(
          ERROR,
          props.intl.formatMessage({ id: 'Opss...' }),
          props.intl.formatMessage({ id: 'Fetching list of categories failed.' }),
        );
      });
  }, [isProcessing]);

  const deleteCategory = (id) => {
    setIsProcessing(true);

    categoryService
      .delete(id)
      .then(() => {
        notify(
          SUCCESS,
          props.intl.formatMessage({ id: 'Yay' }),
          props.intl.formatMessage({ id: 'Category has been deleted.' }),
        );
        history.push('/categories-list');
      })
      .catch(() => {
        notify(
          ERROR,
          props.intl.formatMessage({ id: 'Opss...' }),
          props.intl.formatMessage({ id: 'Category deleting failed.' }),
        );
      })
      .finally(() => {
        setIsProcessing(false);
      });
  };

  return (
    <>
      <h2>
        <FormattedMessage id="Categories list" />
      </h2>
      <Link to="/category" className={classes.buttonContainer}>
        <Button
          variant="contained"
          color="primary"
          className={classes.button}
          startIcon={<AddIcon />}
        >
          <FormattedMessage id="Add category" />
        </Button>
      </Link>
      <CategoryDataTable
        data={categories}
        isProcessing={isProcessing}
        isMounting={isMounting}
        handleDelete={deleteCategory}
      />
    </>
  );
};

CategoriesList.propTypes = {};

const enhance = compose(
  connect((state) => state.authReducer),
  withRestrictedAccess,
  withLayout,
  injectIntl,
);

export default enhance(CategoriesList);
