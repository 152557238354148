import React from 'react';
import { injectIntl } from 'react-intl';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import PropTypes from 'prop-types';

const AlertDialog = ({
  isDialogOpen,
  handleAccept,
  handleDismiss,
  title,
  content,
  confirmButton,
  cancelButton,
  intl,
}) => (
  <div>
    <Dialog
      open={isDialogOpen}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {content}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleDismiss} color="secondary">
          {cancelButton || intl.formatMessage({ id: 'No' })}
        </Button>
        <Button onClick={handleAccept} color="primary" autoFocus>
          {confirmButton || intl.formatMessage({ id: 'Yes' })}
        </Button>
      </DialogActions>
    </Dialog>
  </div>
);

AlertDialog.propTypes = {
  isDialogOpen: PropTypes.bool.isRequired,
  handleAccept: PropTypes.func.isRequired,
  handleDismiss: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
  intl: PropTypes.any.isRequired,
  confirmButton: PropTypes.string,
  cancelButton: PropTypes.string,
};

AlertDialog.defaultProps = {
  confirmButton: null,
  cancelButton: null,
};

export default injectIntl(AlertDialog);
