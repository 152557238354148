const validationLogStyle = 'background: #fcba03; color: #000000';

const validationLog = (content) => {
  /* eslint-disable no-console */
  console.log('%c----------------------------------------', validationLogStyle);
  console.log(content);
  console.log('%c++++++++++++++++++++++++++++++++++++++++', validationLogStyle);
  /* eslint-enable no-console */
};

export default {
  validationLog,
};
