import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl } from 'react-intl';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import withLayout from '../../higherOrderComponents/withLayout';
import withRestrictedAccess from '../../higherOrderComponents/withRestrictedAccess';
import { attributesGroupService } from '../../services/api/attributesGroupService';
import { ERROR, notify, SUCCESS } from '../../services/notification';
import { makeStyles } from '@material-ui/core/styles';
import AlertDialog from '../Utils/Dialog';

const AttributesGroupsList = (props) => {
  const [attributesGroups, setAttributesGroups] = useState([]);
  const [isProcessing, setIsProcessing] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [selectedId, setSelectedId] = useState(null);
  const history = useHistory();
  const droppableContainerId = 'droppable-attributes-groups-list';
  const classes = useStyles();

  useEffect(() => {
    attributesGroupService
      .getAll()
      .then((res) => {
        setAttributesGroups(res.data);
      })
      .catch(() => {
        notify(
          ERROR,
          props.intl.formatMessage({ id: 'Opss...' }),
          props.intl.formatMessage({ id: 'Fetching list of attributes groups groups failed.' }),
        );
      });
  }, [isProcessing]);

  const deleteAttributesGroup = (id) => {
    setIsProcessing(true);

    attributesGroupService
      .delete(id)
      .then(() => {
        notify(
          SUCCESS,
          props.intl.formatMessage({ id: 'Yay' }),
          props.intl.formatMessage({ id: 'Attributes group has been deleted.' }),
        );
        history.push('/attributes-groups-list');
      })
      .catch(() => {
        notify(
          ERROR,
          props.intl.formatMessage({ id: 'Opss...' }),
          props.intl.formatMessage({ id: 'Attributes group deleting failed.' }),
        );
      })
      .finally(() => {
        setIsProcessing(false);
      });
  };

  const onDragEnd = result => {
    const { destination, source, draggableId } = result;

    if (!destination) {
      return;
    }

    if (destination.droppableId === source.droppableId && destination.index === source.index) {
      return;
    }

    const newAttributesGroups = attributesGroups.map(attribute => Object.assign({}, attribute));

    newAttributesGroups.splice(source.index, 1);
    newAttributesGroups.splice(destination.index, 0, attributesGroups.find(attribute => attribute.id === draggableId));

    setAttributesGroups(newAttributesGroups);

    attributesGroupService
      .reorder(newAttributesGroups.map((attributesGroup, index) => (
        {
          id: attributesGroup.id,
          sorting: index + 1,
        }
      )))
      .catch(() => {
        notify(
          ERROR,
          props.intl.formatMessage({ id: 'Opss...' }),
          props.intl.formatMessage({ id: 'Reordering attributes groups failed.' }),
        );
      });
  };

  return (
    <>
      <h2>
        <FormattedMessage id="Attributes groups list" />
      </h2>
      <Paper>
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId={droppableContainerId}>
            {(provided) => (
              <div
                ref={provided.innerRef}
                {...provided.droppableProps}
              >
                {attributesGroups.map((attribute, index) => (
                  <Draggable draggableId={attribute.id} key={attribute.id} index={index}>
                    {(provided) => (
                      <div
                        key={attribute.id}
                        ref={provided.innerRef}
                        className={classes.rowContainer}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                      >
                        <span>
                          {attribute.name}
                        </span>
                        <span>
                          <Link to={`attributes-group/${attribute.id}`}>
                            <IconButton
                              color="primary"
                              aria-label="edit attributes group"
                            >
                              <EditIcon />
                            </IconButton>
                          </Link>
                          <IconButton
                            color="secondary"
                            aria-label="delete attributes group"
                            onClick={() => {
                              setSelectedId(attribute.id);
                              setIsDialogOpen(true);
                            }}
                          >
                            <DeleteIcon />
                          </IconButton>
                        </span>
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </Paper>
      <AlertDialog
        isDialogOpen={isDialogOpen}
        handleAccept={
          () => {
            deleteAttributesGroup(selectedId);
            setIsDialogOpen(false);
            setSelectedId(null);
          }
        }
        handleDismiss={() => setIsDialogOpen(false)}
        title={props.intl.formatMessage({ id: 'Deleting item' })}
        content={props.intl.formatMessage({ id: 'Are you sure you want to delete this item?' })}
      />
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  rowContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '10px 30px',
    background: '#fff',
    borderBottom: '1px solid #eee'
  }
}));

AttributesGroupsList.propTypes = {};

const enhance = compose(
  connect((state) => state.authReducer),
  withRestrictedAccess,
  withLayout,
  injectIntl,
);

export default enhance(AttributesGroupsList);
