import React from 'react';
import { injectIntl } from 'react-intl';
import DataTable from './DataTable';
import Actions from './Parts/Actions';
import EditAction from './Parts/Actions/EditAction';
import DeleteAction from './Parts/Actions/DeleteAction';

const PlaceDataTable = (props) => {
  const url = '/place';

  const columns = [
    {
      name: props.intl.formatMessage({ id: 'Name' }),
      selector: 'name',
      sortable: true,
    },
    {
      name: props.intl.formatMessage({ id: 'Description' }),
      selector: 'description',
      sortable: true,
    },
    {
      name: props.intl.formatMessage({ id: 'Created at' }),
      selector: 'createdAt',
      sortable: true,
    },
    {
      name: props.intl.formatMessage({ id: 'Actions' }),
      width: '160px',
      cell: (row) => (
        <Actions
          actions={[
            <EditAction
              url={url}
              item={row}
              isProcessing={props.isProcessing}
            />,
            <DeleteAction
              url={url}
              item={row}
              isProcessing={props.isProcessing}
              handleDelete={props.handleDelete}
            />,
          ]}
        />
      ),
    },
  ];

  return (
    <DataTable
      url={url}
      columns={columns}
      data={props.data}
      isProcessing={props.isProcessing}
      isMounting={props.isMounting}
    />
  );
};

export default injectIntl(PlaceDataTable);
