import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Icon } from 'leaflet';
import { Map, TileLayer, Marker, Popup } from 'react-leaflet';
import { FormattedMessage } from 'react-intl';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import EditIcon from '@material-ui/icons/Edit';
import Progress from '../../Datatable/Parts/Progress';

const PlacesMap = (props) => {
  const position = [process.env.REACT_APP_MAP_POSITION_LATITUDE, process.env.REACT_APP_MAP_POSITION_LONGITUDE];
  const classes = useStyles();

  const iconResolver = (place) => {
    return place.attributesIds.includes(props.pinColorsBase) ? greenIcon : redIcon;
  };

  return (
    <div className={clsx(classes.map, 'leaflet-container')}>
      {props.isMounting && <Progress />}
      {!props.isMounting && (
        <Map center={position} zoom={process.env.REACT_APP_MAP_POSITION_ZOOM} style={{ width: '100%', height: '100%' }}>
          <TileLayer
            attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />
          {props.filteredPlaces.map(place => (
            <Marker position={[place.latitude, place.longitude]} icon={iconResolver(place)} key={place.id}>
              <Popup>
                <h3>{place.name}</h3>
                <p>{place.description}</p>
                <Link to={`/place/${place.id}`} className={classes.buttonContainer}>
                  <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    className={classes.button}
                    startIcon={<EditIcon />}
                  >
                    <FormattedMessage id="Edit place" />
                  </Button>
                </Link>
              </Popup>
            </Marker>
          ))}
        </Map>
      )}
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  map: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: '800px'
  },
}));

const iconConfig = {
  shadowUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.7/images/marker-shadow.png',
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  shadowSize: [41, 41]
};

const greenIcon = new Icon({
  iconUrl: 'https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-green.png',
  ...iconConfig
});

const yellowIcon = new Icon({
  iconUrl: 'https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-gold.png',
  ...iconConfig
});

const redIcon = new Icon({
  iconUrl: 'https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-red.png',
  ...iconConfig
});

PlacesMap.propTypes = {
  isMounting: PropTypes.bool.isRequired,
  filteredPlaces: PropTypes.array.isRequired,
  pinColorsBase: PropTypes.string.isRequired
};

export default PlacesMap;
