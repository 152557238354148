import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { injectIntl } from 'react-intl';
import PlaceForm from '../Places/PlaceForm';
import withRestrictedAccess from '../../higherOrderComponents/withRestrictedAccess';
import withLayout from '../../higherOrderComponents/withLayout';
import { placeService } from '../../services/api/placeService';
import { notify, SUCCESS, ERROR } from '../../services/notification';
import logger from '../../services/logger';

const PlaceEdit = (props) => {
  const { id } = props.match.params;
  const isAddMode = !id;
  const history = useHistory();

  const createPlace = (values, actions) => {
    placeService
      .create(values)
      .then(() => {
        notify(
          SUCCESS,
          props.intl.formatMessage({ id: 'Yay' }),
          props.intl.formatMessage({ id: 'Place has been added.' }),
        );
        history.push('/places-list');
      })
      .catch((e) => {
        logger.validationLog(e.response.data);

        notify(
          ERROR,
          props.intl.formatMessage({ id: 'Opss...' }),
          e.response.data?.type === 'validation_error'
            ? props.intl.formatMessage({ id: 'Validation error. Check console logs.' })
            : props.intl.formatMessage({ id: 'Adding new place failed.' }),
        );
      })
      .finally(() => {
        actions.setSubmitting(false);
      });
  };

  const updatePlace = (id, values, actions) => {
    placeService
      .update(id, values)
      .then(() => {
        notify(
          SUCCESS,
          props.intl.formatMessage({ id: 'Yay' }),
          props.intl.formatMessage({ id: 'Place has been updated.' }),
        );
        history.push('/places-list');
      })
      .catch((e) => {
        logger.validationLog(e.response.data);

        notify(
          ERROR,
          props.intl.formatMessage({ id: 'Opss...' }),
          e.response.data?.type === 'validation_error'
            ? props.intl.formatMessage({ id: 'Validation error. Check console logs.' })
            : props.intl.formatMessage({ id: 'Updating place failed.' }),
        );
      })
      .finally(() => {
        actions.setSubmitting(false);
      });
  };

  return (
    <>
      <h2>{ props.intl.formatMessage({ id: isAddMode ? 'Add place' : 'Update place' }) }</h2>
      <PlaceForm
        id={id}
        isAddMode={isAddMode}
        create={createPlace}
        update={updatePlace}
      />
    </>
  );
};

PlaceEdit.propTypes = {};

const enhance = compose(
  connect((state) => state.authReducer),
  withRestrictedAccess,
  withLayout,
  injectIntl,
);

export default enhance(PlaceEdit);
