import React from 'react';
import { injectIntl } from 'react-intl';

const NoData = (props) => (
  <div>
    <span>{props.intl.formatMessage({ id: 'No data' })}</span>
  </div>
);

export default injectIntl(NoData);
