import { Redirect } from 'react-router-dom';
import React from 'react';
import PropTypes from 'prop-types';

export default function withRestrictedAccess(WrappedComponent) {
  const Wrapper = (props) => {
    const error = '';
    return (
      <div>
        {(props.authenticated && <WrappedComponent {...props} />)}
        {(!props.authenticated) && (<Redirect to={{ pathname: '/login', state: { error } }} />)}
      </div>
    );
  };

  Wrapper.propTypes = {
    authenticated: PropTypes.bool.isRequired,
    roles: PropTypes.array.isRequired,
    match: PropTypes.object.isRequired,
    intl: PropTypes.object.isRequired,
  };

  return Wrapper;
}
