import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  progress: {
    marginTop: theme.spacing(10),
    marginBottom: theme.spacing(10),
  },
}));

const Progress = () => {
  const classes = useStyles();

  return (
    <div>
      <CircularProgress color="primary" size={60} className={classes.progress} />
    </div>
  );
};

export default Progress;
