import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import { Provider } from 'react-redux';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import ButterToast, { POS_TOP, POS_RIGHT } from 'butter-toast';
import { IntlProvider } from 'react-intl';
import App from './App';
import * as serviceWorker from './serviceWorker';
import store from './services/store';
import messages from './translations/pl.json';

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#0e2539',
    },
    secondary: {
      main: '#f31f43',
    },
  },
});

ReactDOM.render(
  <Provider store={store}>
    <MuiThemeProvider theme={theme}>
      <IntlProvider locale="pl" messages={messages}>
        <App />
      </IntlProvider>
    </MuiThemeProvider>
    <ButterToast position={{
      vertical: POS_TOP,
      horizontal: POS_RIGHT,
    }}
    />
  </Provider>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
