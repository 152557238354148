import React, { useEffect, useState } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl } from 'react-intl';
import withRestrictedAccess from '../../higherOrderComponents/withRestrictedAccess';
import withLayout from '../../higherOrderComponents/withLayout';
import PlacesStatusChart from '../Dashboard/PlacesStatusChart';
import FilterablePlacesMap from '../Dashboard/FilterablePlacesMap';
import { placeService } from '../../services/api/placeService';
import { settingsService } from '../../services/api/settingsService';
import { ERROR, notify } from '../../services/notification';
import 'leaflet/dist/leaflet.css';

const Dashboard = (props) => {
  const [places, setPlaces] = useState([]);
  const [pinColorsBase, setPinColorsBase] = useState(null);
  const [isMounting, setIsMounting] = useState(true);

  useEffect(() => {
    settingsService
      .getByName('pinColorsBase')
      .then((settingResource) => {
        setPinColorsBase(settingResource.data.value?.attributeId);

        placeService
          .getAll()
          .then((res) => {
            const places = res.data.map(place => ({...place, attributesIds: place.attributes.map(attribute => attribute.id)}));

            setPlaces(places);
            setIsMounting(false);
          })
          .catch(() => {
            notify(
              ERROR,
              props.intl.formatMessage({ id: 'Opss...' }),
              props.intl.formatMessage({ id: 'Fetching list of places failed.' }),
            );
          });
      })
      .catch(() => {
        notify(
          ERROR,
          props.intl.formatMessage({ id: 'Opss...' }),
          props.intl.formatMessage({ id: 'Fetching single setting failed.' }),
        );
      });
  }, []);

  return (
    <>
      <h2>
        <FormattedMessage id="Dashboard" />
      </h2>
      <FilterablePlacesMap
        places={places}
        isMounting={isMounting}
        pinColorsBase={pinColorsBase}
      />
      <PlacesStatusChart places={places} isMounting={isMounting} />
    </>
  );
};

Dashboard.propTypes = {};

const enhance = compose(
  connect((state) => state.authReducer),
  withRestrictedAccess,
  withLayout,
  injectIntl,
);

export default enhance(Dashboard);
