import React from 'react';
import PropTypes from 'prop-types';
import ReactDataTable, { createTheme } from 'react-data-table-component';
import { IconButton, TextField } from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';
import { injectIntl } from 'react-intl';
import NoData from './Parts/NoData';
import Progress from './Parts/Progress';

const styles = {
  headRow: {
    style: {
      backgroundColor: '#f2f7fa',
      borderRadius: '5px',
      minHeight: '45px',
      borderBottomWidth: '0',
      color: '#0e2539',
    },
  },
  rows: {
    style: {
      minHeight: '40px',
    },
  },
  headCells: {
    style: {
      color: '#0e2539',
      fontSize: '14px',
      fontWeight: '400',
    },
  },
  cells: {
    style: {
      fontSize: '12px',
    },
  },
  tableWrapper: {
    style: {
      display: 'block',
    },
  },
};

const DataTable = (props) => {
  const [filterId, setFilterId] = React.useState('');
  const [filterText, setFilterText] = React.useState('');
  const filteredItems = props.data.filter((item) => {
    let filterIdCondition = true;
    let filterTextCondition = true;

    if (filterId || filterText) {
      filterIdCondition = item.id && item.id.toLowerCase().includes(filterId.toLowerCase());

      const nameCondition = item.name && item.name.toLowerCase().includes(filterText.toLowerCase());
      const descriptionCondition = item.description
        && item.description.toLowerCase().includes(filterText.toLowerCase());

      filterTextCondition = nameCondition || descriptionCondition;

      const filterIdConditionMet = filterId && filterIdCondition;
      const filterTextConditionMet = filterText && filterTextCondition;

      return filterIdConditionMet || filterTextConditionMet;
    }

    return true;
  });

  const subHeader = React.useMemo(() => {
    const handleClearText = () => {
      if (filterText) {
        setFilterText('');
      }
    };

    const handleClearId = () => {
      if (filterId) {
        setFilterId('');
      }
    };

    return (
      <>
        <>
          <TextField
            id="filter-id"
            type="text"
            placeholder={props.intl.formatMessage({ id: 'ID' })}
            aria-label="Search id"
            value={filterId}
            onChange={(e) => { setFilterId(e.target.value); }}
          />
          <IconButton onClick={handleClearId}>
            <ClearIcon />
          </IconButton>
        </>
        <>
          <TextField
            id="filter-text"
            type="text"
            placeholder={props.intl.formatMessage({ id: 'Name or description' })}
            aria-label="Search text"
            value={filterText}
            onChange={(e) => { setFilterText(e.target.value); }}
          />
          <IconButton onClick={handleClearText}>
            <ClearIcon />
          </IconButton>
        </>
      </>
    );
  }, [filterId, filterText]);

  return (
    <ReactDataTable
      {...props}
      data={filteredItems}
      noHeader={props.title === null}
      subHeaderComponent={subHeader}
      noDataComponent={props.isMounting ? <div /> : props.noDataComponent}
      theme="placeInNutshell"
      customStyles={styles}
      progressPending={props.isMounting}
      progressComponent={<Progress />}
      paginationComponentOptions={{
        rowsPerPageText: props.intl.formatMessage({ id: 'Rows per page:' }),
        rangeSeparatorText: props.intl.formatMessage({ id: 'of' }),
        noRowsPerPage: false,
        selectAllRowsItem: true,
        selectAllRowsItemText: props.intl.formatMessage({ id: 'All' }),
      }}
    />
  );
};

createTheme('placeInNutshell', {
  text: {
    primary: '#929faf',
    secondary: '#000',
  },
  background: {
    default: '#fff',
  },
  context: {
    background: '#fff',
    text: '#929faf',
  },
  divider: {
    default: '#eeefef',
  },
  button: {
    default: '#009dd7',
  },
  sortFocus: {
    default: '#009dd7',
  },
  highlightOnHover: {
    default: '#f2f7fa',
    text: '#0e2539',
  },
});

DataTable.propTypes = {
  ...ReactDataTable.propTypes,
  url: PropTypes.string.isRequired,
  columns: PropTypes.array.isRequired,
  isProcessing: PropTypes.bool.isRequired,
  isMounting: PropTypes.bool.isRequired,
};

DataTable.defaultProps = {
  ...ReactDataTable.defaultProps,
  title: null,
  keyField: 'id',
  striped: false,
  highlightOnHover: true,
  pointerOnHover: false,
  noDataComponent: <NoData />,
  style: null,
  responsive: false,
  disabled: false,
  noHeader: false,
  subHeader: true,
  pagination: true,
  paginationServer: false,
  sortServer: false,
  defaultSortField: null,
  persistTableHead: true,
  paginationRowsPerPageOptions: [10, 25, 50, 100],
  paginationPerPage: 25,
};

export default injectIntl(DataTable);
