import React, { useEffect, useState } from 'react';
import {Link, useHistory} from 'react-router-dom';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl } from 'react-intl';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import withLayout from '../../higherOrderComponents/withLayout';
import withRestrictedAccess from '../../higherOrderComponents/withRestrictedAccess';
import { attributeService } from '../../services/api/attributeService';
import { ERROR, notify, SUCCESS } from '../../services/notification';
import { makeStyles } from '@material-ui/core/styles';
import AlertDialog from '../Utils/Dialog';

const AttributesList = (props) => {
  const [attributes, setAttributes] = useState([]);
  const [isProcessing, setIsProcessing] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [selectedId, setSelectedId] = useState(null);
  const history = useHistory();
  const droppableContainerId = 'droppable-attributes-list';
  const classes = useStyles();

  useEffect(() => {
    attributeService
      .getAll()
      .then((res) => {
        setAttributes(res.data);
      })
      .catch(() => {
        notify(
          ERROR,
          props.intl.formatMessage({ id: 'Opss...' }),
          props.intl.formatMessage({ id: 'Fetching list of attributes failed.' }),
        );
      });
  }, [isProcessing]);

  const deleteAttribute = (id) => {
    setIsProcessing(true);

    attributeService
      .delete(id)
      .then(() => {
        notify(
          SUCCESS,
          props.intl.formatMessage({ id: 'Yay' }),
          props.intl.formatMessage({ id: 'Attribute has been deleted.' }),
        );
        history.push('/attributes-list');
      })
      .catch(() => {
        notify(
          ERROR,
          props.intl.formatMessage({ id: 'Opss...' }),
          props.intl.formatMessage({ id: 'Attribute deleting failed.' }),
        );
      })
      .finally(() => {
        setIsProcessing(false);
      });
  };

  const onDragEnd = result => {
    const { destination, source, draggableId } = result;

    if (!destination) {
      return;
    }

    if (destination.droppableId === source.droppableId && destination.index === source.index) {
      return;
    }

    const newAttributes = attributes.map(attribute => Object.assign({}, attribute));

    newAttributes.splice(source.index, 1);
    newAttributes.splice(destination.index, 0, attributes.find(attribute => attribute.id === draggableId));

    setAttributes(newAttributes);

    attributeService
      .reorder(newAttributes.map((attribute, index) => (
        {
          id: attribute.id,
          sorting: index + 1,
        }
      )))
      .catch(() => {
        notify(
          ERROR,
          props.intl.formatMessage({ id: 'Opss...' }),
          props.intl.formatMessage({ id: 'Reordering attributes failed.' }),
        );
      });
  };

  return (
    <>
      <h2>
        <FormattedMessage id="Attributes list" />
      </h2>
      <Paper>
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId={droppableContainerId}>
            {(provided) => (
              <div
                ref={provided.innerRef}
                {...provided.droppableProps}
              >
                {attributes.map((attribute, index) => (
                  <Draggable draggableId={attribute.id} key={attribute.id} index={index}>
                    {(provided) => (
                      <div
                        key={attribute.id}
                        ref={provided.innerRef}
                        className={classes.rowContainer}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                      >
                        <span>
                          {attribute.name}
                        </span>
                        <span>
                          <Link to={`attribute/${attribute.id}`}>
                            <IconButton
                              color="primary"
                              aria-label="edit attribute"
                            >
                              <EditIcon />
                            </IconButton>
                          </Link>
                          <IconButton
                            color="secondary"
                            aria-label="delete attribute"
                            onClick={() => {
                              setSelectedId(attribute.id);
                              setIsDialogOpen(true);
                            }}
                          >
                            <DeleteIcon />
                          </IconButton>
                        </span>
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </Paper>
      <AlertDialog
        isDialogOpen={isDialogOpen}
        handleAccept={
          () => {
            deleteAttribute(selectedId);
            setIsDialogOpen(false);
            setSelectedId(null);
          }
        }
        handleDismiss={() => setIsDialogOpen(false)}
        title={props.intl.formatMessage({ id: 'Deleting item' })}
        content={props.intl.formatMessage({ id: 'Are you sure you want to delete this item?' })}
      />
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  rowContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '10px 30px',
    background: '#fff',
    borderBottom: '1px solid #eee'
  }
}));

AttributesList.propTypes = {};

const enhance = compose(
  connect((state) => state.authReducer),
  withRestrictedAccess,
  withLayout,
  injectIntl,
);

export default enhance(AttributesList);
