import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import {FormattedMessage, injectIntl} from 'react-intl';
import IconButton from '@material-ui/core/IconButton';
import FilterListIcon from '@material-ui/icons/FilterList';
import Grid from '@material-ui/core/Grid';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import Input from '@material-ui/core/Input/Input';
import MenuItem from '@material-ui/core/MenuItem';
import TripleSwitch from '../../FormElements/TripleSwitch';
import { makeStyles } from '@material-ui/core/styles';
import { compose } from 'redux';

const FiltersPanel = (props) => {
  const [isFiltersPanelOpen, setIsFiltersPanelOpen] = useState(false);
  const classes = useStyles();

  const getSingleFilterValue = (attribute) => {
    if (props.activeFiltersWithPositiveCondition.some(attributeId => attributeId === attribute.id)) {
      return true
    }

    if (props.activeFiltersWithNegativeCondition.some(attributeId => attributeId === attribute.id)) {
      return false;
    }

    return null;
  };

  return (
    <>
      <div className={classes.filtersPanelToggle}>
        {isFiltersPanelOpen && <Button
          size="small"
          color="secondary"
          onClick={() => {
            props.resetFilters();
          }}
        >
          <FormattedMessage id="Clear" />
        </Button>}
        <IconButton
          color={`${isFiltersPanelOpen ? 'secondary' : 'primary'}`}
          aria-label="toggle filters panel"
          onClick={() => {
            setIsFiltersPanelOpen(!isFiltersPanelOpen);
          }}
        >
          <FilterListIcon />
        </IconButton>
      </div>
      {isFiltersPanelOpen && <Grid
        className={classes.filtersPanel}
        container
        justify="center"
        spacing={3}
      >
        <Grid item md={6} xs={12}>
          <FormControl style={{width: '100%'}}>
            <InputLabel id="categories-select-label">
              <FormattedMessage id="Categories" />
            </InputLabel>
            <Select
              labelId="categories-select-label"
              id="categories-select"
              multiple
              value={props.activeCategories}
              onChange={(e) => {props.setActiveCategories(e.target.value)}}
              input={<Input />}
              MenuProps={MenuProps}
            >
              {props.categories && props.categories.map((category) => (
                <MenuItem key={category.id} value={category.id}>{category.name}</MenuItem>
              ))}
            </Select>
            <TripleSwitch
              id="published"
              name={props.intl.formatMessage({ id: 'Published' })}
              handleChange={(e) => props.setIsPublished(e.target.value)}
              value={props.isPublished}
            />
          </FormControl>
        </Grid>
        <Grid item md={6} xs={12}>
          {props.attributes && props.attributes.map(attribute => (
            <TripleSwitch
              id={attribute.id}
              name={attribute.name}
              handleChange={(e) => props.updateActiveFilters(e, attribute)}
              value={getSingleFilterValue(attribute)}
            />
          ))}
        </Grid>
      </Grid>}
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  filtersPanelToggle: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  filtersPanel: {
    paddingBottom: theme.spacing(3),
  }
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

FiltersPanel.propTypes = {
  isPublished: PropTypes.bool.isRequired,
  setIsPublished: PropTypes.func.isRequired,
  categories: PropTypes.array.isRequired,
  activeCategories: PropTypes.array.isRequired,
  setActiveCategories: PropTypes.func.isRequired,
  attributes: PropTypes.array.isRequired,
  updateActiveFilters: PropTypes.func.isRequired,
  activeFiltersWithPositiveCondition: PropTypes.array.isRequired,
  activeFiltersWithNegativeCondition: PropTypes.array.isRequired,
  resetFilters: PropTypes.func.isRequired,
};

const enhance = compose(
  injectIntl,
);

export default enhance(FiltersPanel);
