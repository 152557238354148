import React from 'react';
import { Link } from 'react-router-dom';
import { injectIntl } from 'react-intl';
import EditIcon from '@material-ui/icons/Edit';
import { IconButton } from '@material-ui/core';

const EditAction = (props) => (
  <>
    <Link to={`${props.url}/${props.item.id}`}>
      <IconButton
        aria-label="edit"
        color="primary"
        disabled={props.isProcessing}
      >
        <EditIcon />
      </IconButton>
    </Link>
  </>
);

export default injectIntl(EditAction);
