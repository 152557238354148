import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Chip from '@material-ui/core/Chip';
import RoomIcon from '@material-ui/icons/Room';
import { makeStyles } from '@material-ui/core/styles';
import Pie from '../Utils/Charts/Pie';
import { injectIntl } from 'react-intl';
import Progress from '../Datatable/Parts/Progress';

const STATUS_VISITED = 'STATUS_VISITED';
const STATUS_TO_VISIT = 'STATUS_TO_VISIT';
const STATUS_TO_CHECK = 'STATUS_TO_CHECK';

const PlacesStatusChart = (props) => {
  const [placesStatusSummary, setPlacesStatusSummary] = useState([]);
  const [placesNumber, setPlacesNumber] = useState(0);
  const classes = useStyles();

  useEffect(() => {
    const summary = {
      STATUS_VISITED: {
        'id': props.intl.formatMessage({ id: 'Visited' }),
        'label': props.intl.formatMessage({ id: 'Visited' }),
        'value': 0,
        'color': '#008450'
      },
      STATUS_TO_VISIT: {
        'id': props.intl.formatMessage({ id: 'To visit' }),
        'label': props.intl.formatMessage({ id: 'To visit' }),
        'value': 0,
        'color': '#b81d13'
      },
      STATUS_TO_CHECK: {
        'id': props.intl.formatMessage({ id: 'To check' }),
        'label': props.intl.formatMessage({ id: 'To check' }),
        'value': 0,
        'color': '#efb700'
      }
    };

    props.places.map(item => {
      const markerStatus = statusResolver(item);

      summary[markerStatus].value++;
    });

    setPlacesNumber(props.places.length);

    setPlacesStatusSummary([
      summary[STATUS_VISITED],
      summary[STATUS_TO_VISIT],
      summary[STATUS_TO_CHECK],
    ]);
  }, [props.places]);

  const statusResolver = (marker) => {
    if (marker.isToCheck) {
      return STATUS_TO_CHECK;
    }

    return marker.hasPhotos ? STATUS_VISITED : STATUS_TO_VISIT;
  };

  return (
    <div className={classes.root}>
      {props.isMounting && <Progress />}
      {!props.isMounting && (
        <>
          <Pie data={placesStatusSummary} />
          <Chip
            icon={<RoomIcon />}
            label={`Sumarycznie punktów: ${placesNumber}`}
            color="primary"
            variant="outlined"
          />
        </>
      )}
    </div>
  )
};

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    maxHeight: '400px',
    marginBottom: '50px'
  },
}));

PlacesStatusChart.propTypes = {
  places: PropTypes.array,
  isMounting: PropTypes.bool,
};

export default injectIntl(PlacesStatusChart);
