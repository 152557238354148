import React from 'react';
import PropTypes from 'prop-types';

const Actions = ({ actions }) => (
  <div className="actions">
    {actions.map((action) => action)}
  </div>
);

Actions.propTypes = {
  actions: PropTypes.array.isRequired,
};

export default Actions;
