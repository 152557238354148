import axios from 'axios';
import { cloneDeep } from 'lodash';
import store from '../store';
import { parseJwt } from '../jwtParser';

const successCallback = (response) => response;
const errorCallback = (error) => {
  if (error.config && error.config.isRetryRequest === true) {
    if (error.response.status === 401) {
      store.dispatch({ type: 'LOGGED_OUT' });
    }
    return Promise.reject(error);
  }

  if (error.response?.status === 401) {
    const requestClone = cloneDeep(error.config);

    return refresh()
      .then(() => axios({
        ...requestClone,
        headers: {
          ...requestClone.headers,
          Authorization: `Bearer ${window.localStorage.getItem('authToken')}`,
        },
        isRetryRequest: true,
      }));
  }

  return Promise.reject(error);
};

const apiService = axios.create({ baseURL: process.env.REACT_APP_API });
apiService.defaults.headers.common['Accept-Language'] = 'pl';
apiService.interceptors.response.use(successCallback, errorCallback);
apiService.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem('authToken')}`;

const refreshTokenUrl = '/token/refresh';
export const refresh = () => new Promise((resolve, reject) => apiService
  .post(
    refreshTokenUrl,
    {
      refresh_token: window.localStorage.getItem('authRefreshToken'),
      impersonated: parseJwt(window.localStorage.getItem('authToken'))?.impersonated,
    },
    {
      isRetryRequest: true,
    },
  )
  .then((response) => {
    window.localStorage.setItem('authToken', response.data.token);
    window.localStorage.setItem('authRefreshToken', response.data.refresh_token);
    apiService.defaults.headers.common.Authorization = `Bearer ${response.data.token}`;
    resolve(response);
  })
  .catch((err) => reject(err)));

export default apiService;
