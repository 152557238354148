import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Formik, Form, Field } from 'formik';
import { TextField, CheckboxWithLabel, SimpleFileUpload } from 'formik-material-ui';
import * as Yup from 'yup';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Button, FormControl, Grid, InputLabel, MenuItem } from '@material-ui/core';
import { attributeService } from '../../services/api/attributeService';
import { notify, ERROR } from '../../services/notification';
import { attributesGroupService } from '../../services/api/attributesGroupService';

const AttributeForm = (props) => {
  const formikRef = useRef();
  const [attributesGroups, setAttributesGroups] = useState(null);

  useEffect(() => {
    if (!props.isAddMode && props.id !== null) {
      attributeService
        .getById(props.id)
        .then((res) => {
          Object.keys(initialValues).forEach((field) => {
            if (field === 'attributes') {
              const selected = res.data[field].map((collection) => collection.id);

              formikRef.current.setFieldValue(field, selected, false);
            } else {
              formikRef.current.setFieldValue(field, res.data[field], false);
            }
          });
        })
        .catch(() => {
          notify(
            ERROR,
            props.intl.formatMessage({ id: 'Opss...' }),
            props.intl.formatMessage({ id: 'Fetching attribute failed.' }),
          );
        });
    }

    attributesGroupService
      .getAll()
      .then((res) => {
        setAttributesGroups(res.data);
      })
      .catch(() => {
        notify(
          ERROR,
          props.intl.formatMessage({ id: 'Opss...' }),
          props.intl.formatMessage({ id: 'Fetching list of attributes groups groups failed.' }),
        );
      });
  }, []);

  let initialValues = {
    name: '',
    icon: '',
    description: '',
    attributesGroup: null,
    isActive: false,
    isDevelopmentOnly: false,
  };

  const validationSchema = Yup.object({
    name: Yup.string()
      .required(props.intl.formatMessage({ id: 'Field is required' }))
      .max(255),
    icon: Yup.string()
      .nullable()
      .max(255),
    description: Yup.string()
      .nullable()
      .max(10000),
    attributesGroup: Yup.string()
      .nullable(),
    isActive: Yup.bool(),
    isDevelopmentOnly: Yup.bool(),
  });

  const onSubmit = (values, actions) => {
    if (props.isAddMode) {
      props.create(values, actions);
    } else {
      props.update(props.id, values, actions);
    }
  };

  return (
    <Formik
      innerRef={formikRef}
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
      validateOnBlur
      validateOnChange
      render={(formikProps, isSubmitting) => (
        <Form
          method="POST"
          action="#"
        >
          <Grid
            container
            justify="center"
            spacing={3}
          >
            <Grid item md={8}>
              <Grid container spacing={3}>
                <Grid item sm={8}>
                  <Field
                    component={TextField}
                    name="name"
                    margin="normal"
                    required
                    fullWidth
                    label={props.intl.formatMessage({ id: 'Name' })}
                  />
                </Grid>
                <Grid item sm={4}>
                  <Field
                    component={SimpleFileUpload}
                    name="icon"
                    label={props.intl.formatMessage({ id: 'Icon' })}
                  />
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Field
                  component={TextField}
                  name="description"
                  margin="normal"
                  fullWidth
                  multiline
                  rowsMax={Infinity}
                  label={props.intl.formatMessage({ id: 'Description' })}
                />
              </Grid>
              <Grid item xs={12}>
                <FormControl margin="normal" fullWidth>
                  <InputLabel shrink htmlFor="attributesGroup">
                    <FormattedMessage id="Attributes group" />
                  </InputLabel>
                  <Field
                    component={TextField}
                    type="text"
                    name="attributesGroup"
                    margin="normal"
                    fullWidth
                    select
                    validateOnBlur
                    validateOnChange
                  >
                    {attributesGroups && attributesGroups.map((attributesGroup) => (
                      <MenuItem key={attributesGroup.id} value={attributesGroup.id}>{attributesGroup.name}</MenuItem>
                    ))}
                  </Field>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <Field
                  component={CheckboxWithLabel}
                  name="isActive"
                  type="checkbox"
                  Label={{ label: props.intl.formatMessage({ id: 'Active' }) }}
                />
              </Grid>
              <Grid item xs={12}>
                <Field
                  component={CheckboxWithLabel}
                  name="isDevelopmentOnly"
                  type="checkbox"
                  Label={{ label: props.intl.formatMessage({ id: 'Development only' }) }}
                />
              </Grid>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                size="large"
                disabled={isSubmitting}
              >
                {props.intl.formatMessage({ id: 'Save' })}
              </Button>
            </Grid>
          </Grid>
        </Form>
      )}
    />
  );
};

AttributeForm.propTypes = {
  id: PropTypes.string,
  isAddMode: PropTypes.bool.isRequired,
  create: PropTypes.func.isRequired,
  update: PropTypes.func.isRequired,
};

AttributeForm.defaultProps = {
  id: null,
};

export default injectIntl(AttributeForm);
