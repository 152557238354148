import React, { useEffect, useRef, useState } from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { injectIntl } from 'react-intl';
import { Button, Grid } from '@material-ui/core';
import { attributeService } from '../../services/api/attributeService';
import { settingsService } from '../../services/api/settingsService';
import {notify, ERROR, SUCCESS} from '../../services/notification';
import PinColorsBase from './Setting/PinColorsBase';
import logger from '../../services/logger';

const SettingsForm = (props) => {
  const formikRef = useRef();
  const [attributes, setAttributes] = useState(null);
  const [isMounting, setIsMounting] = useState(true);

  useEffect(() => {
    settingsService
      .getAll()
      .then((res) => {
        res.data.map(setting => {
          // @TODO przy wiekszej liczbie ustawien przygotowan resolver i transformer dla poszczegolnych opcji konfiguracyjnych w osobnych plikach
          switch (setting.name) {
            case 'pinColorsBase':
              setting.value = setting.value?.attributeId || null;

              break;
            default:
              break;
          }

          formikRef.current.setFieldValue(setting.name, setting.value, false);
        });

        setIsMounting(false);
      })
      .catch(() => {
        notify(
          ERROR,
          props.intl.formatMessage({ id: 'Opss...' }),
          props.intl.formatMessage({ id: 'Fetching settings failed.' }),
        );
      });

    attributeService
      .getAll()
      .then((res) => {
        setAttributes(res.data);
      })
      .catch(() => {
        notify(
          ERROR,
          props.intl.formatMessage({ id: 'Opss...' }),
          props.intl.formatMessage({ id: 'Fetching attribute failed.' }),
        );
      });
  }, []);

  let initialValues = {
    pinColorsBase: null,
  };

  const validationSchema = Yup.object({
    pinColorsBase: Yup.string()
      .nullable()
  });

  const onSubmit = (values, actions) => {
    update(
      convertValues(values),
      actions
    );
  };

  const convertValues = (values) => {
    const valuesToSend = {};

    // @TODO przy wiekszej liczbie ustawien przygotowan resolver i transformer dla poszczegolnych opcji konfiguracyjnych w osobnych plikach
    Object.keys(values).map(key => {
      switch (key) {
        case 'pinColorsBase':
          valuesToSend[key] = { attributeId: values[key] };

          break;
        default:
          break;
      }
    });

    return valuesToSend;
  };

  const update = (values, actions) => {
    settingsService
      .update(values)
      .then(() => {
        notify(
          SUCCESS,
          props.intl.formatMessage({ id: 'Yay' }),
          props.intl.formatMessage({ id: 'Settings has been saved.' }),
        );
      })
      .catch((e) => {
        logger.validationLog(e.response.data);

        notify(
          ERROR,
          props.intl.formatMessage({ id: 'Opss...' }),
          e.response.data?.type === 'validation_error'
            ? props.intl.formatMessage({ id: 'Validation error. Check console logs.' })
            : props.intl.formatMessage({ id: 'Updating settings failed.' }),
        );
      })
      .finally(() => {
        actions.setSubmitting(false);
      });
  };

  return (
    <Formik
      innerRef={formikRef}
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
      validateOnBlur
      validateOnChange
      render={(formikProps, isSubmitting) => (
        <Form
          method="POST"
          action="#"
        >
          <Grid
            container
            justify="center"
            spacing={3}
          >
            <Grid item md={8}>
              <Grid item xs={12}>
                <PinColorsBase attributes={attributes}/>
              </Grid>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                size="large"
                disabled={isSubmitting}
              >
                {props.intl.formatMessage({ id: 'Save' })}
              </Button>
            </Grid>
          </Grid>
        </Form>
      )}
    />
  );
};

export default injectIntl(SettingsForm);
