import { parseJwt } from '../services/jwtParser';

const token = window.localStorage.getItem('authToken');
let username = 'anon';

if (token) {
  username = parseJwt(window.localStorage.getItem('authToken')).username;
}

const initialState = { authenticated: Boolean(token), username };

export default (state = initialState, action) => {
  switch (action.type) {
    case 'LOGGED_IN':
      return {
        authenticated: true,
        username: action.payload.username,
      };
    case 'LOGGED_OUT':
      return {
        authenticated: false,
        username: 'anon',
      };
    default:
      return state;
  }
};
