import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Formik, Form, Field } from 'formik';
import { TextField, SimpleFileUpload } from 'formik-material-ui';
import { makeStyles } from '@material-ui/core/styles';
import * as Yup from 'yup';
import { injectIntl } from 'react-intl';
import { Avatar, Button, Grid, List, ListItem, ListItemAvatar, ListItemText } from '@material-ui/core';
import ImageIcon from '@material-ui/icons/Image';
import { attributesGroupService } from '../../services/api/attributesGroupService';
import { notify, ERROR } from '../../services/notification';

const AttributesGroupForm = (props) => {
  const formikRef = useRef();
  const [attributes, setAttributes] = useState([]);
  const classes = useStyles();

  useEffect(() => {
    if (!props.isAddMode && props.id !== null) {
      attributesGroupService
        .getById(props.id)
        .then((res) => {
          Object.keys(initialValues).forEach((field) => {
            formikRef.current.setFieldValue(field, res.data[field], false);
          });

          setAttributes(res.data.attributes);
        })
        .catch(() => {
          notify(
            ERROR,
            props.intl.formatMessage({ id: 'Opss...' }),
            props.intl.formatMessage({ id: 'Fetching attributes group failed.' }),
          );
        });
    }
  }, []);

  let initialValues = {
    name: '',
    icon: '',
  };

  const validationSchema = Yup.object({
    name: Yup.string()
      .required(props.intl.formatMessage({ id: 'Field is required' }))
      .max(255),
    icon: Yup.string()
      .nullable()
      .max(255),
  });

  const onSubmit = (values, actions) => {
    if (props.isAddMode) {
      props.create(values, actions);
    } else {
      props.update(props.id, values, actions);
    }
  };

  return (
    <>
      <Formik
        innerRef={formikRef}
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
        validateOnBlur
        validateOnChange
        render={(formikProps, isSubmitting) => (
          <Form
            method="POST"
            action="#"
          >
            <Grid
              container
              justify="center"
              spacing={3}
            >
              <Grid item md={8}>
                <Grid container spacing={3}>
                  <Grid item sm={8}>
                    <Field
                      component={TextField}
                      name="name"
                      margin="normal"
                      required
                      fullWidth
                      label={props.intl.formatMessage({ id: 'Name' })}
                    />
                  </Grid>
                  <Grid item sm={4}>
                    <Field
                      component={SimpleFileUpload}
                      name="icon"
                      label={props.intl.formatMessage({ id: 'Icon' })}
                    />
                  </Grid>
                </Grid>
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  size="large"
                  disabled={isSubmitting}
                >
                  {props.intl.formatMessage({ id: 'Save' })}
                </Button>
              </Grid>
            </Grid>
          </Form>
        )}
      />
      <br />
      <Grid
        container
        justify="left"
        spacing={3}
      >
        <Grid item md={6}>
          <List className={classes.root}>
            {attributes
              .sort((a, b) => {
                if (a.sorting < b.sorting) {
                  return -1;
                }

                if (a.sorting > b.sorting) {
                  return 1;
                }

                return 0;
              })
              .map(attribute => (
                <ListItem>
                  <ListItemAvatar>
                    <Avatar>
                      <ImageIcon />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText primary={attribute.name} secondary={attribute.description} />
                </ListItem>
              ))
            }
          </List>
        </Grid>
      </Grid>
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    backgroundColor: theme.palette.background.default,
  },
}));

AttributesGroupForm.propTypes = {
  id: PropTypes.string,
  isAddMode: PropTypes.bool.isRequired,
  create: PropTypes.func.isRequired,
  update: PropTypes.func.isRequired,
};

AttributesGroupForm.defaultProps = {
  id: null,
};

export default injectIntl(AttributesGroupForm);
