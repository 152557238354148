import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl } from 'react-intl';
import withLayout from '../../higherOrderComponents/withLayout';
import withRestrictedAccess from '../../higherOrderComponents/withRestrictedAccess';
import SettingsForm from '../Settings/SettingsForm';

const Settings = (props) => {
  return (
    <>
      <h2>
        <FormattedMessage id="Settings" />
      </h2>
      <SettingsForm />
    </>
  );
};

Settings.propTypes = {};

const enhance = compose(
  connect((state) => state.authReducer),
  withRestrictedAccess,
  withLayout,
  injectIntl,
);

export default enhance(Settings);
