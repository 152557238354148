import React from 'react';
import { FormattedMessage } from 'react-intl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

const TripleSwitch = (props) => {
  return (
    <div>
      <FormControl style={{width: '100%'}}>
        <InputLabel id={props.id}>{props.name}</InputLabel>
        <Select
          labelId={props.id}
          id={`${props.id}-${props.id}`}
          value={props.value}
          onChange={props.handleChange}
        >
          <MenuItem value={null}>---</MenuItem>
          <MenuItem value={true}><FormattedMessage id="YES" /></MenuItem>
          <MenuItem value={false}><FormattedMessage id="NO" /></MenuItem>
        </Select>
      </FormControl>
    </div>
  );
};

export default TripleSwitch;
