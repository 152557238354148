import { Redirect } from 'react-router-dom';
import React from 'react';
import PropTypes from 'prop-types';

export default function withSessionRedirect(WrappedComponent) {
  const Wrapper = (props) => (
    <div>
      {props.authenticated && <Redirect to={{ pathname: '/' }} />}
      {!props.authenticated && <WrappedComponent {...props} />}
    </div>
  );

  Wrapper.propTypes = {
    authenticated: PropTypes.bool.isRequired,
    intl: PropTypes.object.isRequired,
  };

  return Wrapper;
}
