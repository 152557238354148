import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { Formik, Form, Field } from 'formik';
import { TextField } from 'formik-material-ui';
import * as Yup from 'yup';
import { injectIntl } from 'react-intl';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import { categoryService } from '../../services/api/categoryService';
import { notify, ERROR } from '../../services/notification';

const CategoryForm = (props) => {
  const formikRef = useRef();

  useEffect(() => {
    if (!props.isAddMode && props.id !== null) {
      categoryService
        .getById(props.id)
        .then((res) => {
          Object.keys(initialValues).forEach((field) => {
            formikRef.current.setFieldValue(field, res.data[field], false);
          });
        })
        .catch(() => {
          notify(
            ERROR,
            props.intl.formatMessage({ id: 'Opss...' }),
            props.intl.formatMessage({ id: 'Fetching category failed.' }),
          );
        });
    }
  }, []);

  let initialValues = {
    name: '',
    description: '',
  };

  const validationSchema = Yup.object({
    name: Yup.string()
      .required(props.intl.formatMessage({ id: 'Field is required' }))
      .max(255),
    description: Yup.string()
      .nullable()
      .max(255),
  });

  const onSubmit = (values, actions) => {
    if (props.isAddMode) {
      props.create(values, actions);
    } else {
      props.update(props.id, values, actions);
    }
  };

  return (
    <Formik
      innerRef={formikRef}
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
      validateOnBlur
      validateOnChange
      render={(formikProps, isSubmitting) => (
        <Form
          method="POST"
          action="#"
        >
          <Grid
            container
            justify="center"
            spacing={3}
          >
            <Grid item md={8}>
              <Grid item xs={12}>
                <Field
                  component={TextField}
                  name="name"
                  margin="normal"
                  required
                  fullWidth
                  label={props.intl.formatMessage({ id: 'Name' })}
                />
              </Grid>
              <Grid item xs={12}>
                <Field
                  component={TextField}
                  name="description"
                  margin="normal"
                  fullWidth
                  multiline
                  rowsMax={Infinity}
                  label={props.intl.formatMessage({ id: 'Description' })}
                />
              </Grid>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                size="large"
                disabled={isSubmitting}
              >
                {props.intl.formatMessage({ id: 'Save' })}
              </Button>
            </Grid>
          </Grid>
        </Form>
      )}
    />
  );
};

CategoryForm.propTypes = {
  id: PropTypes.string,
  isAddMode: PropTypes.bool.isRequired,
  create: PropTypes.func.isRequired,
  update: PropTypes.func.isRequired,
};

CategoryForm.defaultProps = {
  id: null,
};

export default injectIntl(CategoryForm);
