import React, { useState } from 'react';
import { injectIntl } from 'react-intl';
import DeleteIcon from '@material-ui/icons/Delete';
import { IconButton } from '@material-ui/core';
import AlertDialog from '../../../Utils/Dialog';

const DeleteAction = (props) => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [selectedId, setSelectedId] = useState(null);

  return (
    <>
      <IconButton
        aria-label="delete"
        onClick={() => { setSelectedId(props.item.id); setIsDialogOpen(true); }}
        color="secondary"
        disabled={props.isProcessing}
      >
        <DeleteIcon />
      </IconButton>
      <AlertDialog
        isDialogOpen={isDialogOpen}
        handleAccept={
          () => {
            props.handleDelete(selectedId);
            setIsDialogOpen(false);
            setSelectedId(null);
          }
        }
        handleDismiss={() => setIsDialogOpen(false)}
        title={props.intl.formatMessage({ id: 'Deleting item' })}
        content={props.intl.formatMessage({ id: 'Are you sure you want to delete this item?' })}
      />
    </>
  );
};

export default injectIntl(DeleteAction);
