import React, { useEffect, useState } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { FormattedMessage, injectIntl } from 'react-intl';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/AddCircle';
import { makeStyles } from '@material-ui/core/styles';
import { ERROR, notify, SUCCESS } from '../../services/notification';
import { placeService } from '../../services/api/placeService';
import withLayout from '../../higherOrderComponents/withLayout';
import withRestrictedAccess from '../../higherOrderComponents/withRestrictedAccess';
import PlaceDataTable from '../Datatable/PlaceDataTable';
import FiltersPanel from '../Dashboard/Map/FiltersPanel';
import { categoryService } from '../../services/api/categoryService';
import { attributeService } from '../../services/api/attributeService';

const useStyles = makeStyles((theme) => ({
  buttonContainer: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(3),
    marginBottom: theme.spacing(3),
    alignSelf: 'flex-end',
    textDecoration: 'none',
  },
  button: {
    width: '180px',
  }
}));

const PlacesList = (props) => {
  const [places, setPlaces] = useState([]);
  const [isProcessing, setIsProcessing] = useState(false);
  const [isMounting, setIsMounting] = useState(true);
  const [isPublished, setIsPublished] = useState(null);
  const [categories, setCategories] = useState([]);
  const [activeCategories, setActiveCategories] = useState([]);
  const [attributes, setAttributes] = useState([]);
  const [activeFiltersWithPositiveCondition, setActiveFiltersPositive] = useState([]);
  const [activeFiltersWithNegativeCondition, setActiveFiltersNegative] = useState([]);
  const history = useHistory();

  const classes = useStyles();

  useEffect(() => {
    placeService
      .getAll()
      .then((res) => {
        const places = res.data.map(place => ({...place, attributesIds: place.attributes.map(attribute => attribute.id)}));

        setPlaces(places);
        setIsMounting(false);
      })
      .catch(() => {
        notify(
          ERROR,
          props.intl.formatMessage({ id: 'Opss...' }),
          props.intl.formatMessage({ id: 'Fetching list of places failed.' }),
        );
      });

    categoryService
      .getAll()
      .then((res) => {
        setCategories(res.data);
      })
      .catch(() => {
        notify(
          ERROR,
          props.intl.formatMessage({ id: 'Opss...' }),
          props.intl.formatMessage({ id: 'Fetching list of categories failed.' }),
        );
      });

    attributeService
      .getAll()
      .then((res) => {
        setAttributes(res.data);
      })
      .catch(() => {
        notify(
          ERROR,
          props.intl.formatMessage({ id: 'Opss...' }),
          props.intl.formatMessage({ id: 'Fetching list of attributes failed.' }),
        );
      });
  }, [isProcessing]);

  const deletePlace = (id) => {
    setIsProcessing(true);

    placeService
      .delete(id)
      .then(() => {
        notify(
          SUCCESS,
          props.intl.formatMessage({ id: 'Yay' }),
          props.intl.formatMessage({ id: 'Place has been deleted.' }),
        );
        history.push('/places-list');
      })
      .catch(() => {
        notify(
          ERROR,
          props.intl.formatMessage({ id: 'Opss...' }),
          props.intl.formatMessage({ id: 'Place deleting failed.' }),
        );
      })
      .finally(() => {
        setIsProcessing(false);
      });
  };

  const updateActiveFilters = (e, attribute) => {
    const value = e.target.value;

    if (null === value) {
      setActiveFiltersPositive(activeFiltersWithPositiveCondition.filter(
        activeFilterId => activeFilterId !== attribute.id)
      );

      setActiveFiltersNegative(activeFiltersWithNegativeCondition.filter(
        activeFilterId => activeFilterId !== attribute.id)
      );

      return;
    }

    if (true === value) {
      setActiveFiltersPositive(activeFiltersWithPositiveCondition.concat([attribute.id]));
      setActiveFiltersNegative(activeFiltersWithNegativeCondition.filter(activeFilterId => (
        activeFilterId !== attribute.id
      )));
    } else {
      setActiveFiltersPositive(activeFiltersWithPositiveCondition.filter(activeFilterId => (
        activeFilterId !== attribute.id
      )));
      setActiveFiltersNegative(activeFiltersWithNegativeCondition.concat([attribute.id]));
    }
  };

  const resetFilters = () => {
    setIsPublished(null);
    setActiveCategories([]);
    setActiveFiltersPositive([]);
    setActiveFiltersNegative([]);
  };

  const getFilteredPlaces = () => {
    let filteredPlaces = places;

    if (null !== isPublished) {
      filteredPlaces = filteredPlaces.filter(place => place.isPublished === isPublished);
    }

    if (activeCategories.length) {
      filteredPlaces = filteredPlaces.filter(place => (
        place.categories.some(category => activeCategories.includes(category.id))
      ));
    }

    if (activeFiltersWithPositiveCondition.length) {
      filteredPlaces = filteredPlaces.filter(place => (
        activeFiltersWithPositiveCondition.every(attributeId => place.attributesIds.includes(attributeId))
      ));
    }

    if (activeFiltersWithNegativeCondition.length) {
      filteredPlaces = filteredPlaces.filter(place => (
        activeFiltersWithNegativeCondition.every(attributeId => !place.attributesIds.includes(attributeId))
      ));
    }

    return filteredPlaces;
  };

  return (
    <>
      <h2>
        <FormattedMessage id="Places list" />
      </h2>
      <Link to="/place" className={classes.buttonContainer}>
        <Button
          variant="contained"
          color="primary"
          className={classes.button}
          startIcon={<AddIcon />}
        >
          <FormattedMessage id="Add place" />
        </Button>
      </Link>
      <FiltersPanel
        isPublished={isPublished}
        setIsPublished={setIsPublished}
        categories={categories}
        activeCategories={activeCategories}
        setActiveCategories={setActiveCategories}
        attributes={attributes}
        updateActiveFilters={updateActiveFilters}
        activeFiltersWithPositiveCondition={activeFiltersWithPositiveCondition}
        activeFiltersWithNegativeCondition={activeFiltersWithNegativeCondition}
        resetFilters={resetFilters}
      />
      <PlaceDataTable
        data={getFilteredPlaces()}
        isProcessing={isProcessing}
        isMounting={isMounting}
        handleDelete={deletePlace}
      />
    </>
  );
};

PlacesList.propTypes = {};

const enhance = compose(
  connect((state) => state.authReducer),
  withRestrictedAccess,
  withLayout,
  injectIntl,
);

export default enhance(PlacesList);
