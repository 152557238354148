import React from 'react';
import { Field, Form, Formik } from 'formik';
import { CheckboxWithLabel, TextField } from 'formik-material-ui';
import Button from '@material-ui/core/Button';
import * as Yup from 'yup';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { FormattedMessage, injectIntl } from 'react-intl';
import { authService } from '../../services/api/authService';
import { login } from '../../reducers/authActions';
import { ERROR, notify } from '../../services/notification';

const SignInForm = (props) => {
  const onSubmit = (values) => {
    authService
      .login(values.username, values.password)
      .then((response) => {
        props.dispatch(login(response.data.token));
      })
      .catch(() => {
        notify(
          ERROR,
          props.intl.formatMessage({ id: 'Opss...' }),
          props.intl.formatMessage({ id: 'Sign in failed. Try again or check your connection, email and password.' }),
        );
      });
  };

  const initialValues = {
    username: '',
    password: '',
    showPassword: false,
  };

  const validationSchema = Yup.object().shape({
    username: Yup.string().required(props.intl.formatMessage({ id: 'Field is required' })),
    password: Yup.string().required(props.intl.formatMessage({ id: 'Field is required' })),
  });

  return (
    <Formik
      initialValues={initialValues}
      validateOnBlur
      validateOnChange
      validationSchema={validationSchema}
      onSubmit={onSubmit}
      render={(formikProps) => (
        <Form>
          <Field
            name="username"
            component={TextField}
            margin="normal"
            required
            fullWidth
            label={props.intl.formatMessage({ id: 'Email' })}
            variant="outlined"
            autoComplete="login"
            autoFocus
          />
          <Field
            name="password"
            component={TextField}
            type={formikProps.values.showPassword ? 'text' : 'password'}
            margin="normal"
            required
            fullWidth
            label={props.intl.formatMessage({ id: 'Password' })}
            autoComplete="current-password"
            variant="outlined"
          />
          <Field
            component={CheckboxWithLabel}
            name="showPassword"
            type="checkbox"
            Label={{ label: props.intl.formatMessage({ id: 'Show password' }) }}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            size="large"
            className={props.styles.submit}
          >
            <FormattedMessage id="Sign in" />
          </Button>
        </Form>
      )}
    />
  );
};

const enhance = compose(
  connect((state) => state.authReducer),
  injectIntl,
);

export default enhance(SignInForm);
