import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Dashboard from './components/Pages/Dashboard';
import PlacesList from './components/Pages/PlacesList';
import PlaceEdit from './components/Pages/PlaceEdit';
import CategoriesList from './components/Pages/CategoriesList';
import CategoryEdit from './components/Pages/CategoryEdit';
import SignIn from './components/Pages/SignIn';
import AttributeEdit from './components/Pages/AttributeEdit';
import AttributesList from './components/Pages/AttributesList';
import AttributesGroupsList from './components/Pages/AttributesGroupsList';
import AttributesGroupEdit from './components/Pages/AttributesGroupEdit';
import Settings from './components/Pages/Settings';

function App() {
  return (
    <Router>
      <Switch>
        <Route exact path="/login" render={(props) => (<SignIn {...props} />)} />
        <Route exact path="/places-list" render={(props) => (<PlacesList {...props} />)} />
        <Route exact path="/place/:id" render={(props) => (<PlaceEdit {...props} />)} />
        <Route exact path="/place" render={(props) => (<PlaceEdit {...props} />)} />
        <Route exact path="/categories-list" render={(props) => (<CategoriesList {...props} />)} />
        <Route exact path="/category/:id" render={(props) => (<CategoryEdit {...props} />)} />
        <Route exact path="/category" render={(props) => (<CategoryEdit {...props} />)} />
        <Route exact path="/attributes-groups-list" render={(props) => (<AttributesGroupsList {...props} />)} />
        <Route exact path="/attributes-group/:id" render={(props) => (<AttributesGroupEdit {...props} />)} />
        <Route exact path="/attributes-group" render={(props) => (<AttributesGroupEdit {...props} />)} />
        <Route exact path="/attributes-list" render={(props) => (<AttributesList {...props} />)} />
        <Route exact path="/attribute/:id" render={(props) => (<AttributeEdit {...props} />)} />
        <Route exact path="/attribute" render={(props) => (<AttributeEdit {...props} />)} />
        <Route exact path="/settings" render={(props) => (<Settings {...props} />)} />
        <Route exact path="/" render={(props) => (<Dashboard {...props} />)} />
      </Switch>
    </Router>
  );
}

export default App;
