import axios from './apiService';

const baseUrl = '/settings';

export const settingsService = {
  getByName,
  getAll,
  update,
};

function getByName(name) {
  return axios.get(`${baseUrl}/${name}`);
}

function getAll() {
  return axios.get(baseUrl);
}

function update(params) {
  return axios.put(`${baseUrl}`, params);
}
