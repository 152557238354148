import React from 'react';
import PropTypes from 'prop-types';
import { FormControl, InputLabel, MenuItem } from '@material-ui/core';
import { Field } from 'formik';
import { TextField } from 'formik-material-ui';
import { FormattedMessage } from 'react-intl';

const PinColorsBase = (props) => {
  return (
    <FormControl margin="normal" fullWidth>
      <InputLabel shrink htmlFor="pinColorsBase">
        <FormattedMessage id="Pin colors base" />
      </InputLabel>
      <Field
        component={TextField}
        type="text"
        name="pinColorsBase"
        margin="normal"
        fullWidth
        select
        validateOnBlur
        validateOnChange
      >
        {props.attributes && props.attributes.map((attribute) => (
          <MenuItem key={attribute.id} value={attribute.id}>{attribute.name}</MenuItem>
        ))}
      </Field>
    </FormControl>
  );
};

PinColorsBase.propTypes = {
  attributes: PropTypes.array.isRequired,
};

export default PinColorsBase;
