import axios from './apiService';

const baseUrl = '/attributes';

export const attributeService = {
  getAll,
  getById,
  create,
  update,
  delete: deleteItem,
  reorder,
};

function getAll() {
  return axios.get(baseUrl);
}

function getById(id) {
  return axios.get(`${baseUrl}/${id}`);
}

function create(params) {
  return axios.post(baseUrl, params);
}

function update(id, params) {
  return axios.put(`${baseUrl}/${id}`, params);
}

function deleteItem(id) {
  return axios.delete(`${baseUrl}/${id}`);
}

function reorder(params) {
  return axios.put(`${baseUrl}/reorder`, params);
}
